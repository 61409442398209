.archived {
  font: map-get($fonts, caption);
  padding: 0.3rem;
  color: map-get($colors, conv-card-archived-color);
  border: none;
  text-align: center;
  margin: auto;
  background-color: map-get($colors, conv-card-archived-bg);
}

.highlight-conv-card-hover:hover {
    background-color: map-get($colors, con-card-hove);

  .d-hover {
    visibility: visible !important;
  }

  .m-mute-hover {
    right: 4rem !important;
    margin-right: 0.8rem !important;
  }

  .m-pin-hover {
    right: 2rem !important;
    margin-right: 0.8rem !important;
  }

  .m-badge-hover {
    right: 2rem !important;
  }

  .r-1-hover {
    right: 2.4rem !important;
    margin-right: 1rem !important;
  }

  .m-archived-hover {
    right: 2.2rem !important;
    margin-right: 2.5rem !important;
  }

}

.b-animation {
  border-radius: 1rem;
  transition: all 0.4s linear;

}

.display-media {
  height: 12.5rem;
  max-width: 100%;

  @media (max-width:768px) {
    height: 7.5rem;
  }
}

.display-video {
  width: 16rem !important;
  height: 12.5rem;

  @media (max-width:768px) {
    width: auto !important;
    max-width: 10rem !important;
    height: 8rem !important;
  }
}

.display-audio {
  width: 16rem;
  height: 3rem;

  @media (max-width:768px) {
    width: 10rem;
  }
}

.upload-icon {
  height: 1.2rem;
  width: 1.2rem;
}

.textMedia {
  width: 50px !important;
  height: 50;
}
.media{
 width : auto ;
 height: 200 ;
}

.hr-separtor {
  display: flex;
  font: map-get($fonts, h5-regular);
  align-items: center;
  text-align: center;
  clear: both;
  color: map-get($colors, conv-hist-date);
}

.hr-separtor::before,
.hr-separtor::after {
  content: '';
  flex: 1;
  border-bottom: 0.0625rem solid map-get($colors, conv-hist-separtor);
}

.hr-separtor::before {
  margin-right: .25rem;
}

.hr-separtor::after {
  margin-left: .25rem;
}


.display-container {
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.n-msg-title {
  width: 95%;
  display: inline-flex;
  color: map-get($colors, send-msg-title) !important;

  @media (max-width: 511px) and (min-width: 445px) {
    width: 94% !important;
  }

  @media (max-width: 444px) and (min-width: 397px) {
    width: 93% !important;
  }

  @media (max-width: 396px) and (min-width: 361px) {
    width: 92% !important;
  }

  @media (max-width: 360px) and (min-width: 334px) {
    width: 91% !important;
  }

  @media (max-width: 333px) and (min-width: 300px) {
    width: 90% !important;
  }
}

.last {
  display: flex;
  justify-content: space-evenly;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 13px 13px;
  opacity: 1;
  font: normal normal normal 11px/15px Silka;
  letter-spacing: 0px;
  color: #818792;
}

.outbound {
  display: inline-flex;
  justify-content: space-between;
  background: map-get($colors, conv-hist-outbound-bg) 0% 0% no-repeat padding-box;
  word-break: break-word;
  max-width: 40% !important;
  color: map-get($colors, conv-hist-outbound-color);
  border-radius: 0.75rem 0.75rem 0 0.75rem;
}

.inbound {
  display: inline-flex;
  justify-content: space-between;
  background: map-get($colors, conv-hist-inbound-bg) 0% 0% no-repeat padding-box;
  color: map-get($colors, conv-hist-inbound-color);
  word-break: break-word !important;
  width: fit-content;
  max-width: 40% !important;
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  font: map-get($fonts , h5-regular);
}

.live-chat-inbound {
  display: inline-flex;
  justify-content: space-between;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  word-break: break-word !important;
  width: fit-content;
  max-width: 80% !important;
  border-radius: 0 1em 1em 1em;
  box-shadow: 0px 0px 25px #0000001C;
}

// .live-chat-inbound span {
//   font: map-get($fonts , live-chat-conversation-history);
// }

// .live-chat-outbound span {
//   font: map-get($fonts , live-chat-conversation-history);
// }

.live-chat-outbound {
  display: inline-flex;
  justify-content: space-between;
  word-break: break-word !important;
  width: fit-content;
  max-width: 80% !important;
  background: #1450FF 0% 0% no-repeat padding-box;
  border-radius: 1em 0 1em 1em;
  color: map-get($colors, conv-hist-inbound-color);
}

.deliverd:after {
  content: '\2713';
  margin-top: -1rem;
  float: right;
  font: map-get($fonts, h5-regular);
}


.contact-details {
  width: 13rem;
  z-index: 1;
  position: absolute;
  top: 0.25rem;
  right: 2rem;
}

@media (min-width: 481px) {
  .contact-details {
    width: 15rem;
    z-index: 1;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}

@media (min-width: 768px) {
  .contact-details {
    width: 20rem;
    z-index: 1;
    position: absolute;
    top: 0.25rem;
    right: 2rem;
  }
}


.badge-label {
  font: map-get($fonts, h5);
  background-color: map-get($colors, send-msg-count-bg);
  font-weight: bold;
  color: map-get($colors, send-msg-count-color);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  text-align: center;
  margin-top: 0.35rem;
}

.send-btn {
  padding-top: 0.1rem !important;
}

.send-button-padding {
  padding: 0.64rem 1.1rem !important;
}

.send-btn-circle {
  border-radius: 50% !important;
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.send-btn-circle-mobile {
  border-radius: 50% !important;
  height: 2rem !important;
  width: 2rem !important;
}

.plus-btn-circle-mobile {
  border-radius: 50% !important;
  height: 2rem !important;
  width: 2rem !important;
}

#plus-btn-circle-mobile-id:focus {
  background: map-get($colors, conv-hist-more) 0% 0% no-repeat padding-box !important;
}

.header-m {
  margin: 0rem 0.8rem 0rem 1.5rem;
}

.send-message-button {
  padding: 0.65rem 1.1rem !important
}

.history-loader {
  color: map-get($colors, conv-hist-loader);
  font-size: 1.5rem;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  animation: load6 1.5s infinite ease, round 1.5s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.conv-hist-border {
  border: 1px solid map-get($colors, conv-hist-border);
  border-bottom: none;
  border-radius: 1rem 1rem 0 0 ;
}

.conv-unread-badge {
  background: map-get($colors, conv-unread-badge) 0% 0% no-repeat padding-box;
  color: map-get($colors, secondary);
  font: map-get($fonts, h6-medium);
  text-align: center;
  position: relative;
  bottom: 3px;
  width: 20px !important;
  height: 20px !important;

  @media (max-width:768px) {
    border: none;
  }
}

.message-composer-hover:hover {
  border: 1px solid map-get($colors, text-field-hover) !important;
}

.message-composer-hover:active {
  border: 1px solid map-get($colors, text-field-hover) !important;
}

.message-composer-hover:focus {
  border: 1px solid map-get($colors, text-field-hover) !important;
}

.message-composer-hover:focus-within {
  border: 1px solid map-get($colors, text-field-hover) !important;
}

.message-composer-hover:focus-visible {
  border: 1px solid map-get($colors, text-field-hover) !important;
}


.cards-visibility-hidden {
  visibility: hidden !important;
}

.cards-visibility {
  visibility: visible !important;
}


.show-conversation {
  position: absolute;

  &-active {
    &.show-active {
      left: 0;
    }

    &.show-archived {
      left: -100%;
    }

    &.show-unread {
      left: -100%;
    }

    &.show-all {
      left: -200%;
    }
  }

  &-archived {
    &.show-active {
      left: 100%;
    }

    &.show-archived {
      left: 0;
    }

    &.show-unread {
      left: -100%;
    }

    &.show-all {
      left: -100%;
    }
  }

  &-unread {
    &.show-active {
      left: 100%;
    }

    &.show-archived {
      left: 100%;
    }

    &.show-unread {
      left: 0;
    }

    &.show-all {
      left: -100%;
    }
  }


  &-all {
    &.show-active {
      left: 200%;
    }

    &.show-archived {
      left: 100%;
    }

    &.show-unread {
      left: 100%;
    }

    &.show-all {
      left: 0;
    }
  }

  &-animation {
    transition: all 0.4s linear;
  }

  &-height {
    height: 0.3rem;
  }

  &-border {
    border-radius: 6px 6px 0px 0px;
  }
}


.con-bubble-option-menu-top {
  top: -0.85rem;
}

.con-bubble-heading {
  padding-bottom: 0.25rem !important;
}

.message-composer-mobile::-webkit-scrollbar {
  // width: 0;
  background: transparent;
}

.display-more-options {
  opacity: 0;
}


.open-more-options:hover {
  .display-more-options {
    opacity: 1;
    cursor: pointer;
  }
}

//Tags display alignment

.tags {
  position: fixed;
  top: 71.5%;
  left: 35.5%;

  @media (max-width: 835px) {
    top: 75%;
    left: 2%;
  }

  @media (min-width: 1050px) and (max-width: 1280px) {
    left: 45%;
  }

  @media (min-width: 956px) and (max-width: 1050px) {
    left: 50%;
  }

  @media (min-width: 860px) and (max-width: 956px) {
    left: 62%;
  }

  @media (min-width: 750px) and (max-width: 860px) {
    left: 70%;
  }

  @media (min-height: 660px) and (max-height: 720px) {
    top: 69%;
  }

  @media (min-height: 600px) and (max-height: 660px) {
    top: 67%;
  }

  @media (min-height: 550px) and (max-height: 600px) {
    top: 64%;
  }

  @media (min-height: 470px) and (max-height: 550px) {
    top: 60%;
  }

  @media (min-height: 430px) and (max-height: 470px) {
    top: 55%;
  }

  @media (min-height: 380px) and (max-height: 430px) {
    top: 50%;
  }

  @media (min-height: 330px) and (max-height: 380px) {
    top: 45%;
  }

  @media (min-height: 280px) and (max-height: 330px) {
    top: 43%;
  }

  @media (min-height:800px) {
    top: 77%;
  }
}

.tags-newMessage {
  position: fixed;
  top: 45%;
  left: 30%;

  @media (max-width: 835px) {
    top: 39.5%;
    left: 4%;
  }

  @media (min-width: 1000px) and (max-width: 1280px) {
    left: 23%;
  }

  @media (min-width: 760px) and (max-width: 1000px) {
    left: 17%;
  }

}


// new-message icons
.send-message-icon-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.send-message-icon-container span img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.send-message-main {
  background: map-get($colors, primary-btn);
  box-shadow: 0px 3px 4px map-get($colors, btn-shadow);
  padding: 1.5rem;
  border-radius: 50%;
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;
}

.send-message-buttons {
  position: absolute;
  width: 100%;
  bottom: 120%;
  text-align: center;
}

.send-message-buttons span {
  display: block;
  border-radius: 50%;
  margin: 0.5rem auto 0;
  opacity: 1;
  position: relative;
  padding: 1.5rem;
  background-color: map-get($colors, primary-icon-hover);
  box-shadow: 0px 2px 4px map-get($colors, send-message-button-shadow);

  &:nth-child(1) {
    transition: opacity 0.2s ease-in-out 0.3s, transform 0.15s ease-in-out;
  }

  &::nth-child(2) {
    background-color: #03a9f4;
    transition: opacity 0.2s ease-in-out 0.25s, transform 0.15s ease-in-out;
  }
}


.DraftEditor-root {
  text-align: initial;
  margin-top: 4px;
  --scrollbar-color: #E4E4E4;
  overflow: hidden;
}

.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: .1px solid transparent;
  position: relative;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  width: 250px;
}

.public-DraftEditorPlaceholder-inner {
  position: relative;
  width: 100%;
}