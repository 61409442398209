button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.primary-btn {
  font: map-get($fonts, h6-regular);
  text-transform: capitalize;
  text-decoration: none !important;
  border-radius: 0.375rem;
  background: map-get($colors, primary-btn);
  color: map-get($colors, primary-btn-color) !important;
  box-shadow: 0px 3px 4px map-get($colors, btn-shadow);
  padding: 0.5rem 1.5rem !important;
  outline: inherit;
  margin: 0.75rem 0;

  &:hover,
  &:focus {
    background: map-get($colors, primary-btn-focus) 0% 0% no-repeat padding-box !important;
  }
}

.new-groupChat-button {
  background: map-get($colors, primary-btn-color);
  box-shadow: 0px 3px 6px map-get($colors, menu-box-shadow);

  &:hover,
  &:focus {
    background: map-get($colors, primary-btn-color) 0% 0% no-repeat padding-box !important;
  }
}

.secondary-btn {
  font: map-get($fonts, h6-regular);
  text-transform: capitalize;
  text-decoration: none !important;
  border: 0.03125rem solid map-get($colors, secondary-btn-color);
  color: map-get($colors, secondary-btn-color);
  // box-shadow: 0px 3px 4px map-get($colors, btn-shadow);
  border-radius: 0.375rem;
  padding: 0.5rem 1.5rem !important;
  outline: inherit;
  margin: 0.75rem 0;

  &:hover,
  &:focus,
  &:focus-within {
    background: map-get($colors, secondary-btn-highlight-hover) !important;
  }
}

.disabled-btn {
  font: map-get($fonts, h6-regular);
  text-transform: capitalize;
  text-decoration: none !important;
  border: 0.0625rem solid map-get($colors, disabled-btn-color) !important;
  border-radius: 0.375rem;
  background: map-get($colors, disabled-btn) !important;
  color: map-get($colors, disabled-btn-color) !important;
  padding: 0.5rem 1.5rem !important;
  box-shadow: none !important;
  pointer-events: none;
  outline: inherit;
  margin: 0.75rem 0;
}

.send-active-btn {
  font: map-get($fonts, h6-regular);
  text-transform: capitalize;
  text-decoration: none !important;
  border-radius: 0.375rem;
  background: map-get($colors, send-message-select-channel) !important;
  color: map-get($colors, primary-btn-font) !important;
  padding: 0.5rem 1.5rem !important;
  box-shadow: none !important;
  pointer-events: none;
  outline: inherit;
  margin: 0.75rem 0;
}

// for small buttons use

.disabled-btn-small {
  font: map-get($fonts, h5-regular) !important;
  text-transform: capitalize;
  text-decoration: none !important;
  border: 0.0625rem solid map-get($colors, disabled-btn-color) !important;
  border-radius: 0.375rem;
  background: map-get($colors, disabled-btn) !important;
  color: map-get($colors, disabled-btn-color) !important;
  box-shadow: none !important;
  padding: 0.5rem 1.5rem !important;
  pointer-events: none;
  outline: inherit;
}

.primary-btn-small {
  background: map-get($colors, primary-btn);
  border-radius: 0.375rem;
  text-transform: capitalize;
  text-decoration: none !important;
  color: map-get($colors, primary-btn-color) !important;
  font: map-get($fonts, h5-regular) !important;
  padding: 0.5rem 1.5rem !important;
  outline: inherit;
  box-shadow: 0px 3px 4px map-get($colors, btn-shadow);

  &:hover,
  &:focus {
    background: map-get($colors, primary-btn-focus) 0% 0% no-repeat padding-box !important;
  }
}

.secondary-btn-small {
  border: 1px solid map-get($colors, secondary-btn-color) !important;
  border-radius: 0.375rem !important;
  text-transform: capitalize;
  text-decoration: none !important;
  color: map-get($colors, secondary-btn-color);
  font: map-get($fonts, h5-regular) !important;
  padding: 0.5rem 1.5rem !important;
  outline: inherit;
  // box-shadow: 0px 3px 4px map-get($colors, btn-shadow);

  &:hover,
  &:focus {
    background: map-get($colors, secondary-btn-highlight-hover) !important;
  }
}

.group-chat-button {
  background: map-get($colors, secondary-btn-highlight-hover) !important;
  border-radius: 1rem !important;
  text-transform: capitalize;
  text-decoration: none !important;
  color: map-get($colors, primary-btn-focus);
  font: map-get($fonts, h5-regular) !important;
  padding: 0.25rem 1rem !important;
  outline: inherit;

  &:hover,
  &:focus {
    background: map-get($colors, secondary-btn-highlight-hover) !important;
  }
}

.group-chat-button-disabled {
  background: map-get($colors, disabled-groupChat-btn) !important;
  border-radius: 1rem !important;
  text-transform: capitalize;
  text-decoration: none !important;
  font: map-get($fonts, h5-regular) !important;
  padding: 0.25rem 1rem !important;
  outline: inherit;
  cursor: default;

}

.loader-primary {
  border: 2px solid #00000000;
  border-radius: 50%;
  border-top: 2px solid map-get($colors, primary-btn-loader);
  border-right: 2px solid map-get($colors, primary-btn-loader);
  border-left: 2px solid map-get($colors, primary-btn-loader);
  width: 12px;
  height: 12px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-secondary {
  border: 2px solid #00000000;
  border-radius: 50%;
  border-top: 2px solid map-get($colors, secondary-btn-loader);
  border-right: 2px solid map-get($colors, secondary-btn-loader);
  border-left: 2px solid map-get($colors, secondary-btn-loader);
  width: 12px;
  height: 12px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}

.counter-btn {
  background: map-get($colors, primary-btn);
  height: 2.125rem !important;

  &-inverted {
    background: map-get($colors, primary-background);
    height: 2.125rem !important;

    &-disabled {
      background: map-get($colors, add-on-ribbon-inactive);
      height: 2.125rem !important;
      cursor: default;
    }
  }

  &-disabled {
    background: map-get($colors, add-on-ribbon-inactive);
    height: 2.125rem !important;
    cursor: default;
  }

  &.left,
  &-disabled.left,
  &-inverted-disabled.left,
  &-inverted.left {
    border-radius: 45% 0 0 45%;
  }

  &.right,
  &-disabled.right,
  &-inverted-disabled.right,
  &-inverted.right {
    border-radius: 0 45% 45% 0;
  }
}

/* Grid Buttons*/

.grid-btn {
  border-radius: 0.325rem;
  padding: 0.5rem 1.5rem !important;
  display: flex;
  align-items: center;
  font: map-get($fonts, h6-regular) !important;

  &-primary {
    background: map-get($grid-colors, primary-btn);
    // box-shadow: 0px 5px 10px map-get($colors, btn-shadow);
    color: map-get($grid-colors, primary-btn-font) !important;
  }

  &-disabled {
    background: map-get($grid-colors, disabled-btn) !important;
    color: map-get($grid-colors, disabled-btn-font) !important;
    pointer-events: none;
  }

  &-text {
    color: map-get($grid-colors, primary-btn-font);
  }

  &:hover,
  &:focus {
    // background: map-get($colors, secondary-btn-highlight-hover) !important;
  }
}