 .circle-background,
 .circle-progress {
   fill: none;
   font: map-get($fonts, h1-semibold);
 }

 .circle-background-mobile,
 .circle-progress {
   fill: none;
   font: map-get($fonts, h1-semibold);
 }

 .circle-text {
   fill: map-get($colors, circular-bar-text-color) !important;
 }

 .circle-background:hover {
   box-shadow: 0px 3px 12px map-get($colors, circular-bar-circle-box-shadow-hover);
   border-radius: 50%;
   font: map-get($fonts, report-progress-bar-hover);
 }

 .circle-background-active {
   fill: map-get($colors, circular-bar-circle-background-active);
 }

 .circle-background-active-shadow {
   box-shadow: 0px 3px 12px map-get($colors, circular-bar-circle-box-shadow-hover);
 }

 // Deliverd
 .deliverd-circle-background {
   stroke: map-get($colors, circular-bar-circle-deliverd-circle-background);
 }

 .deliverd-circle-progress {
   stroke: map-get($colors, circular-bar-circle-deliverd-circle-progress);
   stroke-linecap: round;
   stroke-linejoin: round;
 }

 //send
 .send-circle-background {
   stroke: map-get($colors, circular-bar-circle-send-circle-background);
 }

 .send-circle-progress {
   stroke: map-get($colors, circular-bar-circle-send-circle-progress);
   stroke-linecap: round;
   stroke-linejoin: round;
 }

 // NotSent
 .notsent-circle-background {
   stroke: map-get($colors, circular-bar-circle-notsent-circle-background);
 }

 .notsent-circle-progress {
   stroke: map-get($colors, circular-bar-circle-notsent-circle-progress);
   stroke-linecap: round;
   stroke-linejoin: round;
 }

 // Failed

 .failed-circle-background {
   stroke: map-get($colors, circular-bar-circle-failed-circle-background);
 }

 .failed-circle-progress {
   stroke: map-get($colors, circular-bar-circle-failed-circle-progress);
   stroke-linecap: round;
   stroke-linejoin: round;
 }