.MuiPaper-rounded {
  border-radius: 10px !important;
}

.MuiTableCell-root {
  border: none !important;
}

.MuiMenuItem-root {
  min-height: 1.5rem !important;
}

.MuiInput-underline:after {
  border: none !important;
}

.MuiInput-underline::before {
  border: none !important;
}

.MuiPaper-elevation24 {
  box-shadow: none !important;
}

// Custom

.custom-option {
  &[data-focus="true"] {
    background-color: map-get($colors, send-msg-custom-option) !important;
    border-color: transparent;
    font: map-get($fonts, h6) !important;
  }

  &[aria-selected="true"] {
    background-color: map-get($colors, send-msg-custom-option) !important;
    border-color: transparent;
    font: map-get($fonts, h6) !important;
  }
}

.custom-group-label {
  font: map-get($fonts, h6) !important;
  color: map-get($colors, send-msg-group-label-bg) !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  padding: 0.5rem !important;
  border-bottom: 0.15rem solid map-get($colors, send-msg-group-label-border);
}

.custom-color-chip-1 {
  background: map-get($colors, send-msg-chip1-bg) 0% 0% no-repeat padding-box !important;
  border-radius: 0.375rem !important;
  font: map-get($fonts, h5-regular) !important;
  letter-spacing: 0.24px !important;
  color: map-get($colors, send-msg-chip1-color) !important;
}

.custom-color-chip-2 {
  background: map-get($colors, send-msg-chip2-bg) 0% 0% no-repeat padding-box !important;
  border-radius: 0.375rem !important;
  font: map-get($fonts, h5-regular) !important;
  letter-spacing: 0.24px !important;
  color: map-get($colors, send-msg-chip2-color) !important;
}

.channel-custom-color-chip {
  background: map-get($colors, send-message-select-channel) 0% 0% no-repeat padding-box !important;
  border-radius: 0.375rem !important;
  font: map-get($fonts, h5-regular) !important;
  letter-spacing: 0.24px !important;
  color: map-get($colors, send-msg-chip2-color) !important;
}

.custom-chip-avatar {
  font: map-get($fonts, h6-regular) !important;
  background-color: map-get($colors, send-msg-chip2-badge-bg) !important;
  color: map-get($colors, send-msg-chip2-badge-color) !important;
  border-radius: 20% !important;
  height: 1rem !important;
  width: 1.5rem !important;
  text-align: center !important;
  font-weight: bold !important;
}

.custom-color-chip-3 {
  background: map-get($colors, send-msg-chip3-bg) 0% 0% no-repeat padding-box !important;
  border-radius: 0.375rem !important;
  font: map-get($fonts, h5-regular) !important;
  letter-spacing: 0.24px !important;
  color: map-get($colors, send-msg-chip3-color) !important;
}

.custom-color-chip-4 {
  background: map-get($colors, send-msg-chip4-bg) 0% 0% no-repeat padding-box !important;
  border-radius: 0.375rem !important;
  font: map-get($fonts, h5-regular) !important;
  letter-spacing: 0.24px !important;
  color: map-get($colors, send-msg-chip4-color) !important;
}

.custom-color-chip-5 {
  background: map-get($colors, contact-unsubscribed) 0% 0% no-repeat padding-box !important;
  border-radius: 0.375rem !important;
  font: map-get($fonts, h5-regular) !important;
  letter-spacing: 0.24px !important;
  color: map-get($colors, send-msg-chip4-color) !important;
}


.custom-auto-complete {
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.custom-search-font {
  ::-webkit-input-placeholder {
    font: map-get($fonts, h6) !important;
    color: map-get($colors, send-msg-search-color) !important;
  }
}


.MuiTooltip-tooltipPlacementBottom {
  margin: 0.3rem 0 0 0 !important;
}

.custom-Tooltip {
  margin: 0.3rem 0 0 1.5rem !important;
}

.custom-menu-list {
  color: map-get($colors, menu-list-font-color);
  background: map-get($colors, menu-bg) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 24px map-get($colors, menu-box-shadow);
  border-radius: 8px;
}

.br-text-field {
  border: 1px solid map-get($colors, text-field-border) !important;
}

.MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border: 1px solid map-get($colors, text-field-hover) !important;
}

.MuiOutlinedInput-root .Mui-focused {
  border: 1px solid map-get($colors, text-field-focused) !important;
}

.MuiCheckbox-root {
  color: map-get($colors, checkbox-notchecked) !important;
}

.selected-checkbox {
  color: map-get($colors, checkbox-checked) !important;

  &:hover {
    background-color: transparent !important;
  }
}


.MuiFormLabel-root {
  color: #3E5172 !important;
  font: normal normal normal 14px/21px silkaregular !important;
  margin: auto !important;
  text-align: center !important;
}

.MuiInputBase-input {
  height: auto !important;
}

.focus-hidden .MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.focus-hidden .MuiOutlinedInput-root .Mui-focused {
  border: none !important;
}

.field-border .MuiInput-underline::before {
  border-bottom: 0.03125rem solid map-get($colors, Secure-file-access-remove-btn-primary) !important;
}

.auto-complete-custom-styles .MuiAutocomplete-inputRoot {
  flex-wrap: nowrap;
}